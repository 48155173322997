<template>
    <div>
        <template v-if="list_member.length > 1">
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h2>Envoi de SMS à {{ list_member.length }} Client(s)</h2>
                <SMSnb />
            </vs-col>
        </template>
        <template v-if="list_member.length==1">
            <h2>Envoyer un SMS à <b>{{ list_member[0].first_name }} {{ list_member[0].last_name }}</b></h2>
        </template>
        <hr/>
        <br/>
        <span style="color:red">
            <b>Notice d'utilisation</b><br/>
            <br/>
            - Ne pas modifier le champ [PRENOM], et [HEURE] votre système les remplira automatiquement.<br/>
            - Les SMS de type CAMPAGNE ne doivent pas être envoyés avant 8H et après 20H, le dimanche et jours fériés <br/>
            - Certains opérateurs « low cost », ex : NRJ Mobile ne supportent pas l’affichage de l’émetteur, ils afficheront un numéro à la place : 38689<br/>
            - 1 SMS = 157 caractères.<br/>
            - Les SMS CAMPAGNE doivent posséder le STOP SMS 36111.<br/>
            - Emoticônes &amp; smileys ne sont pas pris en charge.<br/>
        </span>
        <br/>
        <br/>
        Template du message:
        <vs-textarea v-model="template" />
        <br/>
        <table>
            <tr>
                <td>Caractère SMS utilisés:</td>
                <td>{{ character }} / <b>{{ sms_charac*nb_sms }}</b></td>
            </tr>
            <tr>
                <td>Nombre de SMS par client:</td>
                <td>{{ nb_sms }} SMS</td>
            </tr>
            <tr>
                <td><b>Estimation nombre de SMS envoyés total</b>:</td>
                <td><b>{{ nb_sms_total }}</b> SMS</td>
            </tr>
        </table>
        <br/>
        <vs-divider/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <vs-button color="primary" type="filled" v-on:click="retour()">Retour</vs-button>
                <vs-button color="success" type="filled" v-on:click="send()" :disabled="send_mail">Envoyer</vs-button>
            </vs-col>
        </vs-row>
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'
import SMSnb from '@/components/communication/sms_view.vue'

export default {
    components:{
        SMSnb,
	},
    data(){
        return{
            list_member:[],
            template: "Bonjour [PRENOM],",
            send_mail: false,

            character   : 0,
            nb_sms      : 0,
            nb_sms_total: 0,
            sms_charac  : 157,
        }
	},
	mounted:function()
	{
        this.list_member = [];

        //autoRefresh
		autoRefresh.SetCallback(()=>
        {
            if( window.api_selected != undefined)
            {
                this.list_member = window.api_selected;
                window.api_selected = undefined;
            }
            if(this.list_member.length == 0 )
            {
                this.retour()
                return;
            }
            this.updateInfo()
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
        },
        template(){
            this.updateInfo()
        }
    },
    methods:{
		retour()
		{
			this.$router.go(-1)
		},
        updateInfo()
        {
            this.character = this.template.length;

            this.nb_sms = Math.ceil(this.character/this.sms_charac);

            this.nb_sms_total = this.nb_sms*this.list_member.length;
        },

        send()
		{
            if( this.send_mail == true)
                return;
            if(this.template.length == 0 )
            {
                alert("Aucun message saisi !");
                return;
            }
            if(this.list_member.length == 0 )
            {
                alert("Aucun client choisi !")
                return;
            }
            if(!confirm("Confirmez-vous l'envoi ? Cela peut durer plusieurs minutes. Veuillez ne pas quitter la page."))
                return;

            //lock
            this.send_mail = true;

            //Get UID member
            let tab_uid = [];
            for( let i=0; i<this.list_member.length; i++)
                tab_uid.push( this.list_member[i].uid );

            if (sessionStorage.getItem("ongoingSMS") === null) {
                sessionStorage.setItem("ongoingSMS", "true")
                //Send envoi
                this.$srvApi.Req('post', 'studio/smsgroup',{
                    template : this.template,
                    tab_uid  : tab_uid.join(','),
                    /*simu     : "0",*/
                })
                .then((rep)=>{
                    alert('Envoi effectué pour un total de '+rep.data+" SMS")
                    sessionStorage.removeItem("ongoingSMS")
                    this.retour()
                });
            } else {
                alert("Une campagne est déjà en cours d'envoi. Veuillez patienter.")
            }
                
		},
    }
};

</script>